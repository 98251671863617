import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Icofont from "../../UI/Icofont";
import { Box, Heading, Text, Button } from "rebass/styled-components";
import CustomImage from "../../UI/CustomImage";
import Link from "../../UI/Link";

const HeroHeader = ({ data }) => {
  const bgImg = data.blockSettings
    ? data.blockSettings.bgImg
      ? data.blockSettings.bgImg
      : ""
    : "" || "";
  const bgColor = data.blockSettings
    ? data.blockSettings.bg
      ? data.blockSettings.bg
      : false
    : false || false;
  data.logo = data.logo || "";
  data.ctas = data.ctas || [];

  const sx = {
    backgroundImage: `url(${
      typeof bgImg === "string" ? bgImg : bgImg.childImageSharp.fluid.srcWebp
    })`,
    position: "relative",
    ">*": {
      position: "relative",
      zIndex: 1
    }
  };
  if (bgColor) {
    sx[":before"] = {
      content: '""',
      position: "absolute",
      zIndex: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "linear-gradient(180deg, " + bgColor + "99 8%, " + bgColor + " 95%)"
    };
  }
  return (
    <Wrapper
      data={data}
      sx={sx}
      containerProps={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      minHeight="100vh"
      display="flex"
    >
      <Box textAlign="center" width={[1]}>
        <Box textAlign="center" maxWidth="700px" mx="auto">
          <CustomImage p={4} img={data.logo} alt={data.bigTitle} mx="auto" width="400px" display="block" />
        </Box>
        <Heading as="h1" pb={2}>
          {data.bigTitle}
        </Heading>
        <Text color="white" pb={4}>
          {data.subTitle}
        </Text>
        {data.ctas.map((cta, i) => (
          <Link href={cta.href} key={i}>
            <Button>
              <Icofont icon={cta.icon} />
              {cta.label}
            </Button>
          </Link>
        ))}
      </Box>
    </Wrapper>
  );
};

HeroHeader.propTypes = {
  data: PropTypes.shape({
    bigTitle: PropTypes.any,
    blockSettings: PropTypes.shape({
      bg: PropTypes.any,
      bgImg: PropTypes.any,
      variant: PropTypes.any
    }),
    ctas: PropTypes.array,
    logo: PropTypes.any,
    subTitle: PropTypes.any
  })
};

export default HeroHeader;
export const query = graphql`
  fragment HeroHeaderBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
    logo {
      childImageSharp {
        fluid(maxWidth: 700, quality: 64) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      publicURL
    }
    bgImg {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 64) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      publicURL
    }
    subTitle
    ctas {
      href
      icon
      label
    }
  }
`;
